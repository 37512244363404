export const KEY = 'admin';

export const PROF_PERMANENT_LINK = 'profPermanentLink';
export const MOOC_PROF_PERMANENT_LINK = 'moocProfPermanentLink';
export const PERMANENT_LINK_LOADER = 'permanentLinkLoader';

export const PENDING_USERS = 'pendingUsers';
export const PENDING_USERS_STATUS = 'pendingUsersStatus';

export const USERS = 'users';
export const FETCH_USERS_STATUS = 'fetchUsersStatus';

export const UPDATE_SIGNUP_STATUS = 'updateSignUpStatus';
export const GENERATE_QUERY_STATUS = 'generateQueryStatus';
