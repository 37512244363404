import { combineReducers } from '@reduxjs/toolkit';
import appSlice from 'App/slice';
import networkSlice from 'network/slice';
import generatorSlice from 'pages/Generator/slice';
import adminSlice from 'pages/Admin/slice';
import authSlice from 'auth/slice';
import signUpSlice from 'pages/SignUp/slice';
import sessionsSlice from 'pages/Prof/Sessions/slice';
import sessionSlice from 'pages/Prof/Session/slice';
import imagesSlice from 'pages/Prof/Images/slice';
import studentsSlice from 'pages/Prof/Students/slice';
import userSlice from 'pages/User/slice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [signUpSlice.name]: signUpSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [networkSlice.name]: networkSlice.reducer,
  [generatorSlice.name]: generatorSlice.reducer,
  [adminSlice.name]: adminSlice.reducer,
  [imagesSlice.name]: imagesSlice.reducer,
  [sessionsSlice.name]: sessionsSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [studentsSlice.name]: studentsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
});

export default rootReducer;
