import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, STUDENTS, PENDING_STUDENTS,
} from './constants';

const initialState = {
  [STUDENTS]: null,
  [PENDING_STUDENTS]: null,
};

const reducers = {
  setStudents: assignPayloadToKey(STUDENTS),
  setPendingStudents: assignPayloadToKey(PENDING_STUDENTS),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
