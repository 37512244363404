import RoutesBuilder from 'components/RoutesBuilder';
import routes from 'routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Suspense } from 'react';
import LayoutLoading from 'pages/Layout/LayoutLoading';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<LayoutLoading open />}>
        <RoutesBuilder routes={routes} />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
