import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, IMAGES,
} from './constants';

const initialState = {
  [IMAGES]: null,
};

const reducers = {
  setImages: assignPayloadToKey(IMAGES),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
