import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, SESSION, SESSION_USERS, SESSION_CODE,
} from './constants';

const initialState = {
  [SESSION]: null,
  [SESSION_USERS]: null,
};

const reducers = {
  setSession: assignPayloadToKey(SESSION),
  setSessionUsers: assignPayloadToKey(SESSION_USERS),
  setSessionCode: assignPayloadToKey(SESSION_CODE),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
