export enum MainRoutes {
  LOG_IN = '/connexion',
  SIGN_UP = '/enregistrement',
  FORGOT_PASSWORD = '/mot-de-passe-oublie',
  NOT_FOUND = '/non-trouve',
  ACCOUNT_DELETED = '/compte-supprime',
  REINIT_PASSWORD = '/reinitilisation-mot-de-passe',
  LOGGED_OUT = '/deconnexion',
}

export enum SecuredRoutes {
  HOME = '/accueil',
  SESSION = '/session',
  STUDENTS = '/students',
  IMAGES = '/images',
  SESSIONS = '/sessions',
  ACCOUNT = '/mon-compte',
}
