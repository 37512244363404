export const KEY = 'session';

export const SESSION = 'session';
export const SESSION_STATUS = 'sessionStatus';
export const CONNECT_SESSION_STATUS = 'connectSessionStatus';

export const SESSION_USERS = 'sessionUsers';
export const SESSION_USERS_STATUS = 'sessionUsersStatus';

export const SESSION_CODE = 'sessionCode';
export const SESSION_CODE_STATUS = 'sessionCodeStatus';

export const SEND_INVITATIONS_STATUS = 'sendInvitationsStatus';
