import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, PROF_PERMANENT_LINK, MOOC_PROF_PERMANENT_LINK, PENDING_USERS, USERS,
} from './constants';

const initialState = {
  [PROF_PERMANENT_LINK]: null,
  [MOOC_PROF_PERMANENT_LINK]: null,
  [PENDING_USERS]: null,
};

const reducers = {
  setProfPermanentLink: assignPayloadToKey(PROF_PERMANENT_LINK),
  setMoocProfPermanentLink: assignPayloadToKey(MOOC_PROF_PERMANENT_LINK),
  setPendingUsers: assignPayloadToKey(PENDING_USERS),
  setUsers: assignPayloadToKey(USERS),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
