import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, LINKS,
} from './constants';

const initialState = {
  [LINKS]: null,
};

const reducers = {
  setLinks: assignPayloadToKey(LINKS),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
